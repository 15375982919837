.input-style{
    width: 400px;
    height: 38px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-color:rgb(114, 109, 109) !important;
    padding: 1%;
    margin-bottom: 2px;
    border-width: 1px !important;
    margin-left: 15px;
    margin-bottom: 13px;
}
.phone_temp_user{
    border-bottom-color: rgb(114, 109, 109) !important;
    border-radius: 0rem !important;
    border-top: 0rem !important;
    border-left: 0rem !important;
    border-right: 0rem !important;
    width: 51% !important;
}
.phone_temp_cus_m1{
    border-bottom-color: rgb(114, 109, 109) !important;
    border-radius: 0rem !important;
    border-top: 0rem !important;
    border-left: 0rem !important;
    border-right: 0rem !important;
    width: 42.5% !important;
}
.phone_temp_cus_m2{
    border-bottom-color: rgb(114, 109, 109) !important;
    border-radius: 0rem !important;
    border-top: 0rem !important;
    border-left: 0rem !important;
    border-right: 0rem !important;
    width: 47.5% !important;
}
.phone_temp_login{
    border-bottom-color: #ed1b24 !important;
    border-radius: 0rem !important;
    border-top: 0rem !important;
    border-left: 0rem !important;
    border-right: 0rem !important;
    width: 100% !important;
}
.dropdown_add_user{
    border-bottom-color: rgb(114, 109, 109) !important;
}
.dropdown_login_user{
    border-bottom-color: #ed1b24 !important;
}
.flag-dropdown{
    /* border-bottom-color: #ed1b24 !important; */
    border-radius: 0rem !important;
    border-top: 0rem !important;
    border-left: 0rem !important;
    border-right: 0rem !important;
    background-color: transparent !important;
}
.select-style-header{
    width: 300px;
    height: 40px;
    border-color:#ed1b24 !important;
    padding-left: 1%;
    border-width: 1px !important;
    margin-left: 15px;
    /* margin-bottom: 13px; */
    border-radius:0.25rem;
    margin-top: 20px;
}
.input-style-modal{
    width: 250px;
    /* height: 38px; */
    border-left: none;
    border-top: none;
    border-right: none;
    border-color:rgb(114, 109, 109) !important;
    /* padding: 1%; */
    border-width: 1px !important;
    /* margin-left: 7px; */
    /* margin-bottom: -5px; */
}
.add-responsive-modal{
    font-weight: 590;
    margin-left: 30px;
}
.payment-responsive{
    font-weight: 590;
    margin-left: 30px;
}
.add-subgroup-modal{
    font-weight: 590;
    /* margin-left: 30px; */
}
.dropDown-style{
    width: 355px;
    height: 38px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-color:black !important;
    margin-bottom: 2px;
    border-width: 1px !important;
    margin-bottom: 13px;
}
.installation-asset-dropDown-style{
    width: 200px;
    height: 38px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-color:black !important;
    margin-bottom: 2px;
    border-width: 1px !important;
    margin-bottom: 13px;
}
.source-dropDown-style{
    width: 240px;
    height: 38px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-color:black !important;
    margin-left: 15px;
    border-width: 1px !important;
    margin-bottom: 13px;
}
.type-dropDown-style{
    width: 400px;
    height: 38px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-color:black !important;
    /* margin-left: 15px; */
    border-width: 1px !important;
    margin-bottom: 13px;
}
.input-style-responsive{
    width: 357px;
    height: 38px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-color:rgb(114, 109, 109) !important;
    padding: 1%;
    border-width: 1px !important;
    /* margin-left: 7px; */
    margin-bottom: -5px;

}

.input-style-pop-up{
    width: 240px;
    height: 38px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-color:rgb(114, 109, 109)!important;
    padding: 1%;
    margin-bottom: 2px;
    border-width: 1px !important;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 13px;
}
.input-style:focus{
    border-color: red;
}
.input-style-pop-up:focus{
    border-color: red;
}

.add-button-style{
    border-color:#ed1b24;
    color: white;
    width: 100px;
    height: 35px;
    font-size: 18px;
    color: #ed1b24;
    border:solid 1px;
    border-radius: 3px;
    background-color: transparent;
}
.cancle-button-style{
    border-color:#ed1b24;
    color: white;
    width: 100px;
    height: 35px;
    font-size: 18px;
    color: #ed1b24;
    border:solid 1px;
    border-radius: 3px;
    background-color: transparent;
}
.device-button-style{
    border-color:#ed1b24;
    color: white;
    /* width: 100px; */
    height: 35px;
    font-size: 18px;
    color: #ed1b24;
    border:solid 1px;
    border-radius: 3px;
    background-color: transparent;
}
.device-button-style:hover{
    background-color: #ed1b24;
    color: white;
    /* width: 100px; */
    height: 35px;
    font-size: 18px;
    color: white;
    border:solid 1px;
    border-radius: 3px;
}
.add-button-style:hover{
    background-color: #ed1b24;
    color: white;
    width: 100px;
    height: 35px;
    font-size: 18px;
    color: white;
    border:solid 1px;
    border-radius: 3px;
}
.cancle-button-style:hover{
    background-color: #ed1b24;
    color: white;
    width: 100px;
    height: 35px;
    font-size: 18px;
    color: white;
    border:solid 1px;
    border-radius: 3px;
}


.button-container{
    align-items: center;
}

.button-container-responsive{
    align-items: center;
    margin-left: 30px;
    margin-top: 13px;
}

.login-form{
    width: 600px;
}
.table-form{
padding: 10px;}
.radiobutton{
    width:17px;
    height:17px;

}
.list-btn{
    float:left !important;
    height: 40px !important;
    max-width: 200px;
    border-color: #ed1b24 !important;
    color: #ed1b24 !important;
}

.add-btn-left{
    float:left !important;
    margin-left: 10px !important;
    height: 40px !important;
    border-color: #ed1b24 !important;
    color: #ed1b24 !important;
}
.add-btn{
    float:right !important;
    margin-left: 10px !important;
    height: 40px !important;
    border-color: #ed1b24 !important;
    color: #ed1b24 !important;
}
.add-btn:hover{
    background-color: #ed1b24 !important;
    border-color: white !important;
    color:white !important;

}
.add-node{
    height: 40px !important;
    border-color: #ed1b24 !important;
    color: #ed1b24 !important;
}
.add-node:hover{
    background-color: #ed1b24 !important;
    border-color: white !important;
    color:white !important;
}
.add-lable{
    float:right !important;
    margin-left: 10px !important;
    height: 40px !important;
    width: 175px !important;
    border-color: #ed1b24 !important;
    color: #ed1b24 !important;
}
.add-lable:hover{
    background-color: #ed1b24 !important;
    border-color: white !important;
    color:white !important;

}
.add{
    font-weight: 590;
}

.add-responsive{
    font-weight: 590;
    margin-left: 30px;
    margin-top: 10px;
}

.modal-backdrop {
    background-color: #00000061 !important;
  }

.wallet-default-value{
    padding-left: '9px';
     margin-top:'10px';
     color:"grey";
     
}

/* For mobile view */
@media(max-width: 320px){
    .payment-responsive{
        font-weight: 590;
        margin-left: 30px;
    margin-top: 10px;
    }

}

@media (min-width:320px)  and (max-width:767px){
    .add-responsive{
        font-weight: 590;
        margin-left: 7px !important;
        margin-top: 10px;
    }
    .payment-responsive{
        font-weight: 590;
        margin-left: 60px;
        margin-top: 10px;
        }
    .input-style-responsive{
        width: 95%;
        height: 38px;
        border-left: none;
        border-top: none;
        border-right: none;
        border-color:rgb(114, 109, 109) !important;
        padding: 1%;
        border-width: 1px !important;
         margin-left: 7px;
        margin-bottom: -5px;
    }
    .button-container-responsive{
        margin-top: 13px;
        align-items: center;
        margin-left: 10px;
    
    }
    
    

}
  