.delete {
    border: none;
    background: none;
    color: red;
    font-size: 140%;
}
.back {
    border: none;
    background: none;
    color: black;
    font-size: 190%;
}
.back :hover{
    border: none;
    background: rgb(231, 228, 228);
    border-radius: 25px;
    color: black;
    font-size: 120%;
}
.display-back-button{
    display: flex;

}
.edit {
    border: none;
    background: none;
    color: #4AA02C;
    font-size: 140%;
}
.activate-package{
    width:15px;
}
.list {
    border: none;
    background: none;
    color: #191970;
    font-size: 140%;
}
.drop-down-style{
    width: 170px;
    height: 40px;
    /* border-left: none;
    border-top: none;
    border-right: none; */
    border-color:#ed1b24;
    font-size:17px;
    color:slategrey;
    padding: 1%;
    margin-bottom: 2px;
    border-width: 1px;
    /* margin-left: 15px; */
    margin-bottom: 13px;
}
.search-placeholder-style{
    width: 170px;
    height: 40px;
    /* border-left: none;
    border-top: none;
    border-right: none; */
    border-color:#ed1b24;
    font-size:17px;
    color:slategrey;
    padding: 1%;
    margin-bottom: 2px;
    border-width: 1px;
    /* margin-left: 15px; */
    margin-bottom: 13px;
}
font {
    color: rgb(21, 49, 126);
}

.show-title {
    font-weight: bold;
    text-align: left;
}

.show-detail {
    text-align: left;
}

.table-list {
    text-align: left;
}

.table-header {
    background: #ed1b24;
    color: white; 
    font-weight: 800;
    text-transform:uppercase;
    /* font-size: 15px; */
    /* padding: 5px; */
}
.form-header {
    background: white;
    color: #ed1b24; 
    /* font-weight: 800; */
    text-transform:unset;
    font-size: 20px;
    /* padding: 5px; */
}
.form-header-container {
    /* display: grid; */
    /* margin-right: 75%; */
    /* grid-template-columns: 2fr 16em; */
    /* border-bottom: 1px solid rgb(189 186 186 / 50%); */
    padding: 5px;
}
.installation-label{
    color: #0e0708;
    /* font-weight: ; */
    font-size: 16px;
    font-weight: bold;

}
.no-data-available-text{
    /* margin-top: 10px; */
    text-align: center;
    margin: inherit;
}
.table-data {
    color: black;
}
.tbody {
    border-top: none;
}
.search-btn {
    border: none;
    margin-left: 10px;
    background-color: #ed1b24;
    color: white;
    height: 40px;
    /* margin-top: 1px; */
}
.search-box{
    border-color: #ed1b24;
    background-color: white;
}
.table-responsive-stack tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .table-responsive-stack td,
  .table-responsive-stack th {
     display:block;
  /*      
     flex-grow | flex-shrink | flex-basis   */
     -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  }
  .modal-content{   
      position: absolute !important;
      /* overflow: hidden !important; */
  } 
  .modal-custom{
      width: 500px !important;
  }
  .table-responsive-stack .table-responsive-stack-thead {
     font-weight: bold;
  }
  @media screen and (max-width: 768px) {
     .table-responsive-stack tr {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        border-bottom: 3px solid #ccc;
       display:block;        
     }
     /*  IE9 FIX   */
     .table-responsive-stack td {
        float: left\9;
        width:100%;
     }
  }
.modal-backdrop {
    background-color: #00000061 !important;
  }
  