@import "lbd/variables";
@import "lbd/mixins";

@import "lbd/typography";

// Core CSS
@import "lbd/misc";
@import "lbd/sidebar-and-main-panel";
@import "lbd/buttons";
@import "lbd/inputs";

@import "lbd/alerts";
@import "lbd/tables";

@import "lbd/checkbox-radio-switch";
@import "lbd/navbars";
@import "lbd/footers";

// Fancy Stuff
@import "lbd/plugins/animate";
@import "lbd/dropdown";
@import "lbd/cards";
@import "lbd/chartist";
@import "lbd/responsive";
@import "lbd/bootstrap-switch";

// Nucleo Icons
@import "lbd/partial-nucleo-icons";

// React Differences
@import "lbdr/react-differences";

.paymentModal{
    overflow: hidden !important;
}