body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  /* @media (min-width: 320px) and (max-width: 767px) and (orientation:landscape){}
    @media (min-width: 320px) and (max-width: 767px) and (orientation:portrait){}
    @media only screen and (min-width: 992px ) and (max-width: 1200px){}
    @media only screen and (min-width: 768px) and (max-width:1023px)  {}
    @media screen and (min-width: 1024px){}
    @media only screen and (min-width: 1200px) {} */
    
  /* mobile */
  @media screen and (max-width: 767px) {
    .mobile-login-logo {
        width: 100px !important;
    } 
    .mobile-login-text {
        width: 200px !important;
    } 
    .loginText{
        font-weight: bold !important;
        font-weight: "8%" !important;
        font-size: 30px !important;
        margin-top: 10px;
    }
    .eye-password{
        padding-right: 0px !important; 
        position: absolute !important; 
        right: 10% !important; 
        font-size: 23px !important; 
        color: #ed1b24 !important;
    }
    .select-header {
        width: 100%  !important;
    } 
    .select-installation{
        margin-left: 28px;
        margin-top: 20px;
        width: 87%;
    }
    .action{
       display: inline-flex;
    }
    
  }
  /* desktop */
  @media screen and (min-width: 1024px) {
    .mobile-login-logo {
        width: 230px !important;

    } 
    .mobile-login-text {
        width: 450px !important;
    } 
    .loginText{
        font-weight: bold !important;
        font-weight: "8%" !important;
        font-size: 36px !important;
    }
    .eye-password{
        padding-right: 0px !important; 
        position: absolute !important; 
        right: 6% !important; 
        font-size: 23px !important; 
        color: #ed1b24 !important;
    }
    .select-header {
        display: inline-flex !important;
        width: 64%  !important;
    } 
    .select-installation{
        margin-left: 28px;
        margin-top: 20px;
        width: 49.5%;
    }
    .action{
        text-align:center;
    }
    .hub-name{
        margin-left: 32px;
    }
    .item-hub{
        display: flex !important; 
        justify-content: center !important; 
    }
    .balance-item{
        margin-left: 22px;
    }
    .icon-power{
        text-align:center;
    }
    .item-txn{
        position: relative;
        top: -7px;
    }
    .icons-action{
        align-content: center;
        justify-content: center;
    }
  }
  ol.collection {
      margin: 2%;
      padding: 0px;
      }
    
      li {
          list-style: none;
      }
    
      * {
          box-sizing: border-box;
      }
    
      @media screen and (max-width: 736px) {
          .collection-container {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 20px;
          }
          .table-header {
            background: #ed1b24;
            color: white; 
            font-weight: 800;
            text-transform:uppercase;
            /* padding: 5px; */
        
        }
          .item {
              border: 1px solid gray;
              border-radius: 2px;
              padding: 10px;
          }
    
          .collection-container>li:first-child {
              display: none;
          }
    
          .attribute::before {
              content: attr(data-name);
          }
    
          .attribute {
              display: grid;
              grid-template-columns: minmax(9em, 30%) 1fr;
          }
      }
    
      @media screen and (max-width:580px) {
          .collection-container {
              display: grid;
              grid-template-columns: 1fr;
              /* border-bottom: 1px solid gray; */
          }
      }
    
      @media screen and (min-width: 737px) {
          .item-container {
              display: grid;
              grid-template-columns: 2fr 16em;
              border-bottom: 1px solid rgb(189 186 186 / 50%);
              padding: 7px;
          }
    
          .attribute-container {
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(var(--column-width-min), 1fr));
          }
    
          .information {
              --column-width-min: 12em;
          }
    
          .name-organization {
              --column-width-min: 8em;
          }
    
          .price-currency {
              --column-width-min: 6em;
          }
    
          .amount-edit {
              --column-width-min: 5em;
          }
          
          .name-product{
            --column-width-min: 6em;
          }
          .company-edit{
            --column-width-min: 6em;
          }
           /* vendor table */
          .company-url {
            --column-width-min: 5em;
          }
          /* vendor table */
          .email-edit {
            --column-width-min: 5em;
          }
          .attribute {
              /* border: 1px solid gray; */
              padding: 2px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 15px;
          }
    
          /* .item-container:hover {
              background-color: rgb(215, 228, 240);
          } */
          .hover:hover {
            background-color: #f2f2f2;
        }
          .collection-container>.item-container:first-child .attribute {
              /* display: flex; */
              /* align-items: center;
              justify-content: center; */
              text-overflow: initial;
              overflow: auto;
              white-space: normal;
          }
    
      @media screen and (max-width: 736px) {
          .collection-container {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 20px;
          }
          
      }
    
      @media screen and (max-width:550px) {
          .collection-container {
              display: grid;
              grid-template-columns: 1fr;
          }
      }
      }